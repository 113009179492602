// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-assistance-page-js": () => import("./../../../src/templates/assistance-page.js" /* webpackChunkName: "component---src-templates-assistance-page-js" */),
  "component---src-templates-carefree-page-js": () => import("./../../../src/templates/carefree-page.js" /* webpackChunkName: "component---src-templates-carefree-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-faq-page-js": () => import("./../../../src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-privacy-page-js": () => import("./../../../src/templates/privacy-page.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-service-page-js": () => import("./../../../src/templates/service-page.js" /* webpackChunkName: "component---src-templates-service-page-js" */),
  "component---src-templates-signup-page-js": () => import("./../../../src/templates/signup-page.js" /* webpackChunkName: "component---src-templates-signup-page-js" */),
  "component---src-templates-warranty-page-js": () => import("./../../../src/templates/warranty-page.js" /* webpackChunkName: "component---src-templates-warranty-page-js" */)
}

